import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import NewsletterSubscription from 'components/NewsletterSubscription'
import Button from 'components/Button'
import ArrowAltIcon from 'public/icons/arrow-alt.svg'

import style from './PromoBlock.style'

const PromoBlockStyled = styled.div(props => ({ ...style(props) }))

const PromoBlock = ({
    image,
    title,
    titleTag: TitleTag,
    subtitle,
    text,
    buttonText,
    buttonLink,
    newsletter,
    newsletterPlaceholder,
    isHomepage
}) => {
    return (
        <PromoBlockStyled
            isHomepage={isHomepage}
            image={image}
            newsletter={newsletter}
            noContent={!title && !subtitle && !text}>
            <div className="Image" />
            <div className="Content">
                {title ? <TitleTag className="Title" dangerouslySetInnerHTML={{ __html: title }} /> : null}

                {subtitle && <p className="Subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />}

                {/* eslint-disable react/no-danger */}
                {text && <div className="Text" dangerouslySetInnerHTML={{ __html: text }} />}

                {buttonLink && (
                    <Button
                        className="animate"
                        link={buttonLink}
                        variation="primary rounded"
                        white
                        hasIcon
                        small
                        dataAttrs={{
                            'gtm-event': 'link',
                            'gtm-label': title,
                            'gtm-action': buttonText,
                            'gtm-category': 'PromoBlock'
                        }}>
                        {buttonText} <ArrowAltIcon />
                    </Button>
                )}

                {newsletter && (
                    <div className="NewsletterSubscription">
                        <NewsletterSubscription placeholder={newsletterPlaceholder} inverted />
                    </div>
                )}
            </div>
        </PromoBlockStyled>
    )
}

PromoBlock.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titleTag: PropTypes.string,
    text: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    newsletter: PropTypes.bool,
    newsletterPlaceholder: PropTypes.string,
    isHomepage: PropTypes.bool
}

PromoBlock.defaultProps = {
    title: null,
    titleTag: 'h3',
    text: null,
    subtitle: null,
    buttonText: null,
    buttonLink: null,
    newsletter: false,
    newsletterPlaceholder: undefined,
    isHomepage: false
}

export default withTheme(PromoBlock)
