import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, image, newsletter, noContent, isHomepage }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        padding: isHomepage ? 50 : 0
    },

    '& .Image': {
        display: newsletter ? 'none' : 'block',
        overflow: 'hidden',
        height: 260,
        width: '100%',
        background: `url(${image}) no-repeat center top`,
        backgroundSize: 'cover',
        borderRadius: 4,

        [theme.breakpoints.up(480)]: {
            height: 300
        },

        [theme.breakpoints.up('md')]: {
            display: 'block',
            order: 2,
            height: 'auto',
            width: '50%',
            maxWidth: 790,
            borderRadius: '0 4px 4px 0'
        },

        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            left: toRem(-20),
            maxWidth: 'calc(100% + 40px)',
            width: 'calc(100% + 40px)',
            borderRadius: 0
        }
    },

    '& .Content': {
        padding: isHomepage ? toRems([30, 0]) : toRems([30, 20]),
        background: isHomepage ? theme.colors.blueDark : theme.colors.blueLight,

        [theme.breakpoints.up('sm')]: {
            padding: isHomepage ? toRems([30, 20]) : toRems([30, 20])
        },

        [theme.breakpoints.up('md')]: {
            order: isHomepage ? 2 : 1,
            width: '60%',
            borderRadius: '4px 0 0 4px'
        },

        [theme.breakpoints.up('lg')]: {
            padding: isHomepage ? 40 : toRem(40)
        },

        [theme.breakpoints.up('xl')]: {
            padding: isHomepage ? 50 : toRems([120, 70, 120, 160])
        }
    },

    '& .Title': {
        color: isHomepage ? theme.colors.white : theme.colors.goldDark,
        fontSize: isHomepage ? toRem(30) : toRem(25),
        maxWidth: isHomepage ? 600 : 430,
        fontWeight: isHomepage ? 600 : 200,
        marginBottom: isHomepage ? 30 : 0,

        [theme.breakpoints.up('lg')]: {
            fontSize: isHomepage ? toRem(50) : toRem(35),
            marginBottom: isHomepage ? 80 : 20
        }
    },

    '& .Subtitle': {
        fontSize: toRem(19),
        marginBottom: toRem(10),
        color: isHomepage ? theme.colors.goldDark : theme.colors.white,
        fontWeight: isHomepage ? 600 : 200,

        [theme.breakpoints.up('lg')]: {
            fontSize: toRem(24),
            marginBottom: toRem(20)
        }
    },

    '& .Text': {
        marginBottom: toRem(40),
        maxWidth: 430,

        p: {
            marginBottom: toRem(20),
            fontSize: toRem(16)
        },

        [theme.breakpoints.up('md')]: {
            maxWidth: isHomepage ? 800 : 440
        },

        [theme.breakpoints.up('lg')]: {
            fontSize: toRem(16),
            marginBottom: toRem(130)
        }
    },

    '& .NewsletterSubscription': {
        marginTop: noContent ? toRem(45) : toRem(30),
        marginBottom: noContent ? toRem(45) : 0,

        [theme.breakpoints.up('lg')]: {
            marginTop: noContent ? toRem(45) : toRem(95),
            marginBottom: noContent ? toRem(45) : 0,

            input: {
                minWidth: 0
            }
        }
    },

    '& .NewsletterSubscriptionButton': {
        [theme.breakpoints.up('md')]: {
            background: `${theme.colors.blueDark} !important`
        }
    }
})

export default style
